import * as client_hooks from '../../../src/hooks.client.ts';

export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40'),
	() => import('./nodes/41'),
	() => import('./nodes/42'),
	() => import('./nodes/43'),
	() => import('./nodes/44'),
	() => import('./nodes/45'),
	() => import('./nodes/46'),
	() => import('./nodes/47'),
	() => import('./nodes/48'),
	() => import('./nodes/49'),
	() => import('./nodes/50'),
	() => import('./nodes/51'),
	() => import('./nodes/52'),
	() => import('./nodes/53'),
	() => import('./nodes/54'),
	() => import('./nodes/55'),
	() => import('./nodes/56'),
	() => import('./nodes/57'),
	() => import('./nodes/58'),
	() => import('./nodes/59'),
	() => import('./nodes/60')
];

export const server_loads = [];

export const dictionary = {
		"/(app)": [5,[2]],
		"/(app)/accept-terms": [6,[2]],
		"/(app)/agreements": [7,[2]],
		"/(app)/agreements/edit/[id]": [15,[2]],
		"/(app)/agreements/new": [16,[2]],
		"/(app)/agreements/[id=integer]": [8,[2]],
		"/(app)/agreements/[id=integer]/change-history": [10,[2]],
		"/(app)/agreements/[id=integer]/request-for-changes": [11,[2,3]],
		"/(app)/agreements/[id=integer]/request-for-changes/form": [12,[2,3]],
		"/(app)/agreements/[id=integer]/request-for-changes/form/preview": [13,[2,3]],
		"/(app)/agreements/[id=integer]/(success)/request-for-changes/form/success": [9,[2]],
		"/(app)/agreements/[id=integer]/system-notes": [14,[2]],
		"/(app)/bill-claims": [17,[2]],
		"/(app)/bills": [18,[2]],
		"/(app)/bills/[id]": [19,[2]],
		"/(app)/bills/[id]/wip": [20,[2]],
		"/(app)/changes-webhook": [23,[2]],
		"/(app)/changes": [21,[2]],
		"/(app)/changes/[id=integer]": [22,[2]],
		"/(app)/client-vas": [24,[2]],
		"/(app)/clients": [25,[2]],
		"/(app)/clients/edit/[id]": [28,[2]],
		"/(app)/clients/new": [29,[2]],
		"/(app)/clients/[id]": [26,[2]],
		"/(app)/clients/[id]/system-notes": [27,[2]],
		"/(app)/email-template-editor": [30,[2]],
		"/(app)/email-template-editor/edit/[id]": [31,[2]],
		"/(app)/email-template-editor/new": [32,[2]],
		"/(app)/finance-bills": [33,[2]],
		"/(app)/finance-invoices": [34,[2]],
		"/forbidden": [59],
		"/(app)/invoice-claims": [35,[2]],
		"/(app)/invoices": [36,[2]],
		"/(app)/invoices/[id]": [37,[2]],
		"/(app)/invoices/[id]/wip": [38,[2]],
		"/(app)/kanban": [39,[2]],
		"/(app)/login": [40,[2,4]],
		"/(app)/login/forgot-password": [41,[2,4]],
		"/(app)/login/forgot-password/success": [42,[2,4]],
		"/(app)/login/reset-password": [43,[2,4]],
		"/public/print-invoice/[invoice_id]": [~60],
		"/(app)/reports": [44,[2]],
		"/(app)/resources": [45,[2]],
		"/(app)/select-client": [46,[2]],
		"/(app)/settings": [47,[2]],
		"/(app)/sync-logs": [48,[2]],
		"/(app)/unauthorized": [49,[2]],
		"/(app)/users": [50,[2]],
		"/(app)/users/edit/[id=integer]": [52,[2]],
		"/(app)/users/new": [53,[2]],
		"/(app)/users/[id=integer]": [51,[2]],
		"/(app)/vas": [54,[2]],
		"/(app)/vas/edit/[id]": [57,[2]],
		"/(app)/vas/new": [58,[2]],
		"/(app)/vas/[id]": [55,[2]],
		"/(app)/vas/[id]/system-notes": [56,[2]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),
};

export { default as root } from '../root.svelte';